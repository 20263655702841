import React, { useState } from "react";
import "../../styles/Connexion.css"
import $ from "jquery";

function Connexion(){

    const [username, setName] = useState("");
    const [password, setPassword] = useState("");
    const [result, setResult] = useState("");

    const handleChangeUser = (e) => {
        setName(e.target.value);
    };

    const handleChangePass = (e) => {
        setPassword(e.target.value);
    };

    const handleSumbit = (e) => {
        e.preventDefault();
        const form = $(e.target);
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success(data) {
                console.log("Connextion réussite")
                setResult(data);
            },
        });
    };

    return(
        <div className="form">
            <form
                action="index.php"
                method="post"
                onSubmit={(event) => handleSumbit(event)}
            >
                <label htmlFor="username" className="label">Username </label>
                <br/>
                <br/>
                <input
                    type="text"
                    className="input"
                    id="username"
                    name="username"
                    value={username}
                    onInput={(event) => handleChangeUser(event)}
                />
                <br/>
                <br/>
                <label htmlFor="username" className="label">Password </label>
                <br/>
                <br/>
                <input
                    type="password"
                    className="input"
                    id="password"
                    name="password"
                    value={password}
                    onInput={(event) => handleChangePass(event)}
                />
                <br/>
                <br/>
                <br/>
                <button className='button' onClick="" type="submit">Login</button>
            </form>
            <p>{result}</p>
        </div>
    )
}

export default Connexion;