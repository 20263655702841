import '../styles/App.css';
import {BrowserRouter, Routes, Route, Router} from "react-router-dom";
import React from "react";
import Layout from "./beacon/Layout";
import Home from "./pages/Home";
import News from "./pages/News";
import Connexion from "./pages/Connexion";

function App() {

  return (
    <div className="App">
        <BrowserRouter>
            <Routes history={Router.history}>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="news" element={<News />} />
                    <Route path="connexion" element={<Connexion />} />
                </Route>
            </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
