import "../../styles/Twitch.css"
import React from "react";

function Twitch(){
    return(
        <iframe className="twitch" src="https://player.twitch.tv/?channel=jishuashi0&parent=www.jishuashi.fr" frameBorder="0" title="twitch"
                allowFullScreen="true" scrolling="no" height="378" width="620"></iframe>
    )
}

export default Twitch;