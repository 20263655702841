import Twitch from "../beacon/Twitch";
import Youtube from "../beacon/Youtube";
import Discord from "../beacon/Discord";
import React from "react";

function Home() {
    return (
        <div className="Home">
            <div className="content">
                <Twitch />
                <Youtube />
                <Discord />
            </div>
        </div>
    );
}

export default Home;