import Twitter from "../beacon/Twitter";
import Discord from "../beacon/Discord";
import React from "react";

function News() {
    return (
        <div className="News">
            <Twitter />
            <Discord />
        </div>
    );
}

export default News;