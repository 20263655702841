import {Outlet} from "react-router-dom";
import Navigation from "./Navigation";
import React from "react";

const Layout = () => {
    return (
        <>
            <Navigation />
            <Outlet />
        </>
    );
};

export default Layout;