import '../../styles/Navigation.css';
import logo from '../../assets/Jishuashi.png';
import {Link} from "react-router-dom";
import React from "react";

function Navigation(){
    let isLogging = false;
    let result;

    if(isLogging){
        result = <Link to="/connexion">TEST</Link>
    }else
    {
        result = <Link to="/connexion">Connexion</Link>
    }


    return (
        <nav className="navbar background">
            <ul className="nav-list">
                <li><Link to="/">Acceuil</Link></li>
                <li><Link to="/news">News</Link></li>
                <li><a href="./info.php">PHP</a></li>
            </ul>
            <div className="logo">
                <img src={logo} alt="logo"/>
            </div>
            <div className="rightNav">
                {result}
            </div>
        </nav>
    );
}

export default Navigation;