import "../../styles/Twitter.css"
import React from "react";

function Twitter(){
    return(
        <iframe id="twitter-widget-0" scrolling="yes" frameBorder="0" allowTransparency="true" allowFullScreen="true"
                className="twitter" title="Twitter Timeline"
                src="https://syndication.twitter.com/srv/timeline-profile/screen-name/Jishuashi?dnt=false&amp;embedId=twitter-widget-0&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;origin=https%3A%2F%2Fpublish.twitter.com%2F%23&amp;sessionId=cd2cd8cdc7f39e5becb6d55b6b2ee9a18f9be03b&amp;showHeader=true&amp;showReplies=false&amp;transparent=false&amp;widgetsVersion=1bfeb5c3714e8%3A1661975971032"
                ></iframe>
    )
}

export default Twitter;